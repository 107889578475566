<template>
  <section title="">
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                xl="9"
                md="9"
              >
                <h2>Empresas</h2>
              </b-col>
              <b-col
                md="3"
              >
                <b-button
                  v-b-modal.addempresas
                  variant="outline-primary"
                  class="mb-75"

                  fixed
                  block
                > Adicionar Empresa
                </b-button>
              </b-col>
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-primary"
                        class="mr-2"
                        @click="editDados(item)"
                      >
                        Gerenciar Membros
                      </b-button>
                      <b-button
                        v-b-modal.addempresas
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        class="btn-icon rounded-circle"
                        @click="EditarDados(item)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'Status'">
                      <b-badge
                        :variant="NomeStatus(item).cor"
                      >
                        {{ NomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
      <b-modal
        v-if="mostraPopUp"
        id="addempresas"
        title="Adicione um Novo Empresa"
        hide-footer
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Nome"
              label-for="Nome"
            >
              <b-form-input
                id="Nome"
                v-model="nomeEmpresa"
                placeholder="Digite o nome da empresa"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

            <b-form-group
              label="Status"
              label-for="selecionetipo"
            >
              <v-select
                v-model="categoriaTipo"
                label="text"
                :options="opcoesTipo"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="SalvarDados"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-card></section>

</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      id: null,
      categoriaTipo: '',
      nomeEmpresa: '',
      mostraPopUp: true,
      url: 'admin/empresas',
      data: {},
      dadositem: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      opcoesTipo: [
        { value: 1, text: 'Ativo' },
        { value: 2, text: 'Inativo' },
      ],
      columns: [
        {
          label: '#',
          name: 'id',
          orderable: true,
        },
        {
          label: 'Nome',
          name: 'nome',
          orderable: true,
        },
        {
          label: 'Status',
          orderable: true,
        },
        {
          label: 'Ações',
          filterable: false,
        },
      ],
    }
  },
  created() {
    this.BuscarDados(this.url)
  },
  methods: {

    EditarDados(item) {
      localStorage.setItem('dados_empresa', JSON.stringify(item))
      const dados = JSON.parse(localStorage.getItem('dados_empresa'))
      this.id = dados.id
      this.nomeEmpresa = dados.nome
      this.categoriaTipo = dados.status
      if (this.categoriaTipo === 1) {
        this.categoriaTipo = { text: 'Ativo' }
      } else {
        this.categoriaTipo = { text: 'Inativo' }
      }
      this.mostraPopUp = true
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'ALERTA',
        variant: cor,
        solid: true,
      })
    },

    ZerarCampos() {
      const obj = {
        id: this.id,
        nome: this.nomeEmpresa,
        status: this.categoriaTipo.value,
      }
      this.nomeEmpresa = null
      this.categoriaTipo = null
      return obj
    },
    async SalvarDados() {
      if (this.categoriaTipo === null) {
        this.Notificacao('danger', 'Selecione o Status da Empresa')
        return
      }
      const obj = this.ZerarCampos()
      if (this.id === null) {
        this.mostraPopUp = false
        this.reloadTable()
        return
      }
      await this.$http.put(`admin/empresas/${this.id}`, obj).then(resp => {
        this.data = resp.data
        this.reloadTable()
      })
    },
    editDados(item) {
      localStorage.setItem('empresa', JSON.stringify(item))
      this.$router.push({
        name: 'app-membros',
      })
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'light-danger', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'light-success', msg: 'Ativo' }
      }
      if (item.status === 0) {
        return { cor: 'light-warning', msg: 'Fornecedor' }
      } return 'Sem Status'
    },
    BuscarDados(url = this.url, options = this.tableProps) {
      this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    DeletarDados(item) {
      this.$http.delete(`admin/leads/${item.id}`)
      this.reloadTable()
      return ['excluido com sucesso']
    },
  },
}
</script>
